<template>
  <h1>The Click!</h1>
  <button class="btn btn-primary" @click="buttonClicked">Click me!</button>
  <p>{{ counter }}</p>
</template>

<script>
export default {
  name: "TheClick",
  data() {
    return {
      counter: 0
    }
  },
  methods: {
    buttonClicked() {
      if (this.getRandomInt(10) < 3) {
        this.counter = 0;
      } else {
        this.counter++;
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
  }
}
</script>