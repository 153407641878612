import { createRouter, createWebHistory } from "vue-router";

import Home from "@/pages/Home";
import TheClick from "@/pages/games/the-click/TheClick";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/the-click",
        name: "theClick",
        component: TheClick
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;