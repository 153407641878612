<template>
  <div class="hello">
    <h1>Welcome to Your Game Collection</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
